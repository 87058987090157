"use strict";
exports.__esModule = true;
exports.useEmptyListing = void 0;
var getSourceFile_1 = require("utils/getSourceFile");
var useDataFetching_1 = require("hooks/useDataFetching");
var react_redux_1 = require("react-redux");
var useEmptyListing = function () {
    var activeLanguage = (0, react_redux_1.useSelector)(function (state) { return state.language.active; });
    var products = (0, react_redux_1.useSelector)(function (state) { return state.listing.products; });
    var loading = (0, react_redux_1.useSelector)(function (state) { return state.listing.loading; });
    var data = (0, useDataFetching_1.useDataFetching)({
        folder: "/data",
        source: (0, getSourceFile_1.getSourceFile)("empty-search", activeLanguage),
        optional: true
    })[0];
    return {
        data: data,
        show: !loading && !products.length,
        enabled: !!data
    };
};
exports.useEmptyListing = useEmptyListing;
