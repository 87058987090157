// https://developers.google.com/tag-manager/enhanced-ecommerce

import store from 'state/reducers'
import { getMetaOptions } from 'global-content/config'
import { getLanguage } from 'utils'

const contactUsCateogryName = `Contact`
const favoriteCategoryName = `Favorites`
const footerCategoryName = `Footer`
const headerCategoryName = `Header`
// const homepageCategoryName = 'Homepage'
const searchCategoryName = `Search Results`
const errorCategoryName = `Error`
const minicartCategoryName = `Minicart`
const ecommerceCategoryName = `Ecommerce`

const trackEvent = `trackEvent`
const promotionClick = `promotionClick`
const buttonClick = `buttonClick`
const promotionImpression = `promotionImpression`
const socialShare = `Social Share`

const suffixFilters = [
  `-tablet`,
  `-desktop`,
]

// update enhanced ecommerce analytics
export function updateGoogleAnalytics(type, data) {
  const gaMetaData = getMetaOptions(`integrations.googleAnalytics`)
  if (Boolean(gaMetaData) === false) {
    return
  }

  const {
    affiliation,
    country,
    region,
  } = gaMetaData

  data = {
    ...data,
    analyticsTitle: suffixFilters.reduce(
      (result, suffix) => result ? result.replace(suffix, ``) : undefined,
      data?.analyticsTitle
    ),
  }

  // prevent analytics error from interfering with site operation
  try {
    let message, items, step

    switch (type) {
    case `pageview`:
      message = {
        event: type,
        company: affiliation,
        siteCountry: country,
        siteRegion: region,
        siteLanguage: getLanguage(),
      }
      break
    case `virtual_pageview_error`:
      message = {
        event: `pageview_error`,
        company: affiliation,
        siteCountry: country,
        siteRegion: region,
        siteLanguage: getLanguage(),
        page_title: getGaPageTitle(`Application Error`),
        page_location: window.location.origin + `/application_error.html`,
        page_referrer: window.location.href,
      }
      break
    case `virtual_pageview_error_waf`:
      message = {
        event: `pageview_error`,
        company: affiliation,
        siteCountry: country,
        siteRegion: region,
        siteLanguage: getLanguage(),
        page_title: getGaPageTitle(`Application Error WAF`),
        page_location: window.location.origin + `/application_error_waf.html`,
        page_referrer: window.location.href,
      }
      break
    case `virtual_pageview_pageNotFound`:
      message = {
        event: `pageview_pageNotFound`,
        company: affiliation,
        siteCountry: country,
        siteRegion: region,
        siteLanguage: getLanguage(),
        page_title: getGaPageTitle(`Page Not Found`),
        page_location: window.location.origin + `/page_not_found.html`,
        page_referrer: window.location.href,
      }
      break
    case `bannerView`:
      message = {
        event: promotionImpression,
        ecommerce: {
          promoView: {
            promotions: [
              {
                id: {
                  promoURL: data.url,
                },
                name: data.analyticsTitle,
              },
            ],
          },
        },
      }
      break
    case `bannerClick`:
      message = {
        event: promotionClick,
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id: {
                  promoURL: data.url,
                },
                name: data.analyticsTitle,
              },
            ],
          },
        },
      }
      break
    case `buttonClick`:
      message = {
        eventCategory: buttonClick,
        eventAction: `Button has been clicked`,
        eventLabel: data.analyticsTitle,
        event: type,
      }
      break
    case `transactionComplete`: {
      const {
        cartContents,
        orderReference,
        totalItemCost,
        totalDiscountProduct,
        deliveryCost,
        totalDiscountShipping,
        totalTax,
        voucherCode,
        brandCode,
      } = data
      message = {
        event: type,
        ecommerce: {
          currencyCode: getMetaOptions(`currency.code`),
          purchase: {
            actionField: {
              id: orderReference,
              affiliation: affiliation,
              revenue: totalItemCost - totalDiscountProduct,
              grossRevenue: totalItemCost,
              totalDiscountProduct: totalDiscountProduct,
              tax: totalTax,
              shipping: deliveryCost - totalDiscountShipping,
              totalDiscountShipping: totalDiscountShipping,
              coupon: voucherCode || ``,
              paymentType: brandCode,
              dimension7: `website`,
            },
            products: cartContents.map(cartItem => gaEcommerceFormatter({ cartItem })),
          },
        },
      }
      break
    }
    case `addToCart`:
      var {
        buttonType,
        buttonAnchorPosition,
        pageArea,
      } = data
      delete data.buttonType
      delete data.buttonAnchorPosition
      delete data.pageArea
      message = {
        event: type,
        ecommerce: {
          currencyCode: getMetaOptions(`currency.code`),
          add: {
            products: [gaEcommerceFormatter(data)],
          },
        },
        event_params: {
          buttonType,
          buttonAnchorPosition,
          pageArea,
        },
      }
      break
    case `removeFromCart`:
      message = {
        event: type,
        ecommerce: {
          remove: {
            products: [gaEcommerceFormatter(data)],
          },
        },
      }
      break
    case `productImpression`:
      message = {
        event: type,
        ecommerce: {
          impressions: data.items.map(item => gaEcommerceFormatter(
            { algoliaItem: item, list: data.listLocation },
            {
              addList: true,
              addPosition: true,
              doNotAddQuantity: true,
            }
          )),
        },
      }
      break
    case `productDetailImpression`:
    case `productSkuImpression`:
      message = {
        event: type,
        ecommerce: {
          detail: {
            products: [gaEcommerceFormatter(data, { doNotAddQuantity: true })],
          },
        },
      }
      break
    case `checkout`:
      ({
        step,
        items,
      } = data)

      message = {
        event: type,
        ecommerce: {
          checkout: {
            actionField: {
              step,
            },
            products: items.map(cartItem => gaEcommerceFormatter({ cartItem })),
          },
        },
      }
      break
    case `productClick`:
      message = {
        event: type,
        ecommerce: {
          click: {
            actionField: {
              list: data.listLocation,
            },
            products: [gaEcommerceFormatter(
              data,
              {
                addPosition: true,
                doNotAddQuantity: true,
              }
            )],
          },
        },
      }
      break
    case `addToFavorites`:
      // favorites actions use the non-enhanced (GA, non UTM) object syntax
      // ex: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
      // we use the old format but push to dataLayer like it's a modern UTM call, see here:
      // https://developers.google.com/tag-manager/devguide#events

      message = {
        eventCategory: favoriteCategoryName,
        eventAction: `Added Product To Favorites`,
        eventLabel: data.target.slug,
        event: trackEvent,
      }
      break
    case `removeFromFavorites`:
      message = {
        eventCategory: favoriteCategoryName,
        eventAction: `Removed Product From Favorites`,
        eventLabel: data.target.slug,
        event: trackEvent,
      }
      break
    case `emailSignup`:
      message = {
        eventCategory: footerCategoryName,
        eventAction: `Submitted Email`,
        eventLabel: ``, // otherwise datalayer uses label from last event
        event: trackEvent,
      }
      break
    case `pageNotFound`:
      message = {
        eventCategory: errorCategoryName,
        eventAction: `Page Not Found`,
        eventLabel: data.url,
        event: trackEvent,
      }
      break
    case `productNotFound`:
      message = {
        eventCategory: errorCategoryName,
        eventAction: `Product Not Found`,
        eventLabel: data.url,
        event: trackEvent,
      }
      break
    case `contactUs`:
      message = {
        eventCategory: contactUsCateogryName,
        eventAction: `Submitted Contact Form`,
        eventLabel: ``,
        event: trackEvent,
      }
      break
    case `countrySelection`:
      message = {
        eventCategory: footerCategoryName,
        eventAction: `Country Selector Click`,
        eventLabel: data.country,
        event: trackEvent,
      }
      break
    case `shopCountrySiteHeader`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Shop Site Click`,
        eventLabel: data.country,
      }
      break
    case `shopCountrySite`:
      message = {
        eventCategory: footerCategoryName,
        eventAction: `Shop Site Click`,
        eventLabel: data.country,
      }
      break
    case `backToUsSite`:
      message = {
        eventCategory: footerCategoryName,
        eventAction: `Clicked US Site Link`,
        eventLabel: data.url,
        event: trackEvent,
      }
      break
    case `clickCart`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Clicked Cart`,
        eventLabel: ``,
        event: trackEvent,
      }
      break
    case `continueFromMiniCart`:
      message = {
        eventCategory: minicartCategoryName,
        eventAction: `Clicked Continue`,
        eventLabel: ``,
        event: trackEvent,
      }
      break
    case `switchLanguage`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Clicked Language Selector`,
        eventLabel: `Switched Language To ${data.text}`,
        event: trackEvent,
      }
      break
    case `clickLogo`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Clicked Logo`,
        eventLabel: ``,
        event: trackEvent,
      }
      break
    case `emptySearch`:
      message = {
        eventCategory: searchCategoryName,
        eventAction: `Returned Zero Results`,
        eventLabel: data.query,
        event: trackEvent,
      }
      break
    case `paymentFailure`:
      message = {
        eventCategory: ecommerceCategoryName,
        eventAction: `Payment Failure`,
        eventLabel: ``,
        event: trackEvent,
      }
      break
    case `clickCategoryNavLink`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Clicked Category Navigation Link`,
        eventLabel: data.url,
        event: trackEvent,
      }
      break
    case `clickCategoryNavItem`:
      message = {
        eventCategory: headerCategoryName,
        eventAction: `Clicked Category Navigation Item`,
        eventLabel: data.path,
        event: trackEvent,
      }
      break
    case `clickProductTrayMenu`:
      message = {
        eventCategory: ecommerceCategoryName,
        eventAction: `Clicked Product Tray Department`,
        eventLabel: data.analyticsTitle,
        event: trackEvent,
      }
      break
    case `productTrayImpression`:
      message = {
        eventCategory: ecommerceCategoryName,
        eventAction: `Viewed Product Tray`,
        eventLabel: data.analyticsTitle,
        event: trackEvent,
      }
      break
    case `socialShare`:
      message = {
        eventCategory: socialShare,
        eventAction: data.action,
        eventLabel: data.productName,
        event: trackEvent,
      }
      break
    default:
      return Promise.reject(new Error(`Request to report analytics for an unknown type: ${type}`))
    }

    window.dataLayer = window.dataLayer || []
    const {
      protocol,
      hostname,
      pathname,
      search,
    } = window.document.location
    const originalPath = `${protocol}//${hostname}${pathname}${search}`

    const locationAdded = function(dataLayerItem) {
      return dataLayerItem.originalLocation !== undefined
    }

    let analyticsEvent = {
      ...message,
      shoppingSessionId: window.$shoppingSessionId.value,
    }

    // if any items in window.dataLayer contain originalLocation, don't add it
    // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
    if (!window.dataLayer.some(locationAdded)) {
      analyticsEvent.originalLocation = originalPath
    }

    const { userId } = store.getState().account

    if (userId) {
      analyticsEvent.userId = userId
    }

    // console.log(`Event ${type} triggered.`, message) // Uncomment this to debug
    // To debug on localhost, run console.log(window.dataLayer)
    window.dataLayer.push(analyticsEvent)
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

export function gaEcommerceFormatter(data, options = {}) {
  // normalize to the same shape as a cartItem
  const {
    brand,
    categories,
    id,
    itemDiscount,
    list,
    position,
    price,
    quantity,
    sku,
    slug,
    variant,
    availability,
    gender,
  } = normalizeAnalyticsItem(data)

  const sortedCategories = [...categories].sort((a, b) => b.length - a.length)

  const gaObj = {
    category: sortedCategories?.[0]?.tag ? sortedCategories[0].tag : sortedCategories[0],
    id: sku,
    dimension13: id, // product_id
    dimension15: availability,
    name: slug,
    price: price.sale.total,
    variant: variant.join(`|`),
    productId: id,
    gender,
  }

  if (brand) {
    gaObj.brand = brand
  }

  if (!options.doNotAddQuantity) {
    gaObj.quantity = quantity
  }

  if (options.addPosition) {
    gaObj.position = position
  }

  if (options.addList) {
    gaObj.list = list
  }

  if (itemDiscount) {
    gaObj.itemDiscount = itemDiscount
  }

  return gaObj
}

function normalizeAnalyticsItem({
  algoliaItem,
  cartItem,
  product,
  skuOptions,
  quantity,
  list,
}) {
  if (cartItem) {
    return {
      ...cartItem,
      id: cartItem.productId,
      brand: cartItem.brand,
      itemDiscount: cartItem.discounts?.reduce((acc, item) => acc + item.amount, 0),
      list,
      variant: (
        Object.keys(cartItem.options)
          .map(option => cartItem.options[option] && cartItem.options[option].slug)
          .filter(Boolean)
      ),
    }
  }
  // is from product api | skuOptions is unique to product api
  if (skuOptions) {
    return {
      id: product.id,
      brand: product.brand.tag,
      categories: product.categories,
      price: skuOptions.price,
      slug: product.slug,
      sku: skuOptions.sku,
      quantity: quantity,
      variant: Object.keys(skuOptions.options).map(option => skuOptions.options[option]),
      list,
      gender: product.gender || undefined,
    }
  }

  if (algoliaItem) {
    const currencyCountry = getMetaOptions(`currencyCountry`)

    return {
      ...algoliaItem,
      list,
      price: {
        sale: {
          total: algoliaItem.price[currencyCountry].sale,
        },
      },
      brand: algoliaItem.brand.tag,
      variant: [algoliaItem.color.slug],
    }
  }
}

function getGaPageTitle(pageTitle) {
  return `${pageTitle} | ${window.document.title}`
}
