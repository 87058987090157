"use strict";
exports.__esModule = true;
exports.useBrowseBy = void 0;
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("react");
var useGlobalContent_1 = require("hooks/useGlobalContent");
var useBrowseBy = function () {
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var levels = pathname.split("/").slice(1);
    var navTree = (0, useGlobalContent_1.useGlobalContent)("navTree");
    var generateRouteLink = function (path, parents) {
        var cleanParents = parents.filter(Boolean).join("/");
        var base = cleanParents ? "/".concat(cleanParents) : "";
        return "".concat(base, "/").concat(path.startsWith("/") ? path.slice(1) : path);
    };
    var makeLinks = function (routes, parents) {
        return routes
            ? Object.entries(routes)
                .filter(function (_a) {
                var route = _a[1];
                return !route.hide;
            })
                .map(function (_a) {
                var slug = _a[0], route = _a[1];
                return ({
                    label: route.label,
                    path: route.path || generateRouteLink(slug, parents),
                    slug: slug
                });
            })
            : [];
    };
    var walkTree = function (branch, remainingLevels) {
        if (!remainingLevels.length || !branch) {
            return makeLinks(branch, levels.slice(0, -1));
        }
        var currentLevel = remainingLevels[0], nextLevels = remainingLevels.slice(1);
        var nextBranch = branch[currentLevel];
        var childLevel = "l".concat(levels.length - nextLevels.length + 1);
        if (nextBranch === null || nextBranch === void 0 ? void 0 : nextBranch[childLevel]) {
            return walkTree(nextBranch[childLevel], nextLevels);
        }
        return [];
    };
    var build = (0, react_1.useMemo)(function () {
        return walkTree(navTree === null || navTree === void 0 ? void 0 : navTree.l1, levels);
    }, [pathname]);
    return build;
};
exports.useBrowseBy = useBrowseBy;
